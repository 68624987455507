<template>
  <Item
    :item="item"
    :parent="parent"
    class="py-3 -my-2"
    style="color: var(--contrastColor, black)"
    :class="{
      'w-full': width === '1:1',
      'w-1/2': width === '1:2',
      'w-1/3': width === '1:3',
      'w-1/4': width === '1:4',
    }"
  >
    <hr ref="hr" :class="[style]" />
  </Item>
</template>

<script>
// import { dispatch } from 'vuex-pathify'

import { annotate } from 'rough-notation'
import itemMixin from '@/mixins/item'

export default {
  name: 'HorizontalLineItem',

  components: {
    Item: () => import('@/components/Item'),
  },

  mixins: [itemMixin],

  props: {},

  data() {
    return {
      sketchyLine: null,
    }
  },

  computed: {
    hrStyle() {
      return this.item.props.hrStyle
    },

    style() {
      return this.hrStyle.style
    },

    width() {
      return this.hrStyle.width
    },

    height() {
      return this.hrStyle.height
    },
  },

  watch: {
    hrStyle() {
      if (this.sketchyLine) {
        this.sketchyLine.remove()
      }

      if (this.style === 'sketchy') {
        this.drawSketchyLine()
      }
    },
  },

  mounted() {
    this.$nextTick(async () => {
      if (this.style === 'sketchy') {
        await this.$sleep(0)
        this.drawSketchyLine()
      }
    })
  },

  methods: {
    drawSketchyLine() {
      this.sketchyLine = annotate(this.$refs.hr, {
        type: 'underline',
        animate: false,
        iterations: 4,
        strokeWidth: 2,
      })

      this.sketchyLine.show()
    },
  },
}
</script>

<style lang="postcss">
hr {
  border-top: 0;

  &.sketchy {
    @apply -mt-1;
  }

  &.solid {
    height: 0.25rem;
    @apply rounded-full;
    background-color: var(--contrastColor, black);
  }

  &.dashed {
    border-top: 0.25rem;
    border-style: dashed;
    border-color: var(--contrastColor, black);
  }

  &.squiggly {
    position: relative;
    height: 10px;
    mask-image: url(~/assets/squiggle.svg);
    background-color: var(--contrastColor, black);
    mask-position: left;
    mask-size: auto calc(100% - 2px);
    mask-repeat: repeat-x;

    /*&:after {
      position: absolute;
      display: block;
      height: 20px;
      left: 0;
      right: 0;
      font-size: 30px;
      content: 'xxx';
      text-decoration-style: wavy;
      text-decoration-color: #25173a;
      text-decoration-line: underline;
    }*/
  }
}
</style>
